import React from 'react';
import styled from 'styled-components';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import logoFooter from './assets/images/logo-footer.png';
import paymentIcon1 from './assets/images/logo-sbp.svg';
import paymentIcon2 from './assets/images/logo-card2.svg';

const FooterContainer = styled.footer`
  background: #1a1a1a;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Montserrat', sans-serif;
  padding: 2rem 1rem;

  @media (max-width: 768px) {
    padding: 1rem;
  }
`;

const FooterContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1200px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const Logo = styled.div`
  display: flex;
  align-items: center;
  img {
    width: 90px;
    height: 90px;
  }
  margin-right: 1.5rem;

  @media (max-width: 768px) {
    margin-bottom: 1rem;
  }
`;

const NavSection = styled.div`
  display: flex;
  gap: 5rem;
  align-items: flex-start;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 2rem;
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const ColumnTitle = styled.h4`
  margin-bottom: 1rem;
  font-size: 1rem;
  font-weight: 600;

  @media (max-width: 768px) {
    font-size: 0.875rem;
  }
`;

const FooterLink = styled(RouterLink)`
  color: #666;
  text-decoration: none;
  margin-bottom: 0.5rem;
  font-size: 0.875rem;
  font-weight: 400;
  transition: color 0.3s;

  &:hover {
    color: #ffa500;
  }

  @media (max-width: 768px) {
    font-size: 0.75rem;
  }
`;

const ExternalLink = styled.a`
  color: #666;
  text-decoration: none;
  margin-bottom: 0.5rem;
  font-size: 0.875rem;
  font-weight: 400;
  transition: color 0.3s;

  &:hover {
    color: #ffa500;
  }

  @media (max-width: 768px) {
    font-size: 0.75rem;
  }
`;

const LegalSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  text-align: right;
  padding-top: 0.5rem;

  @media (max-width: 768px) {
    align-items: flex-start;
    text-align: left;
    margin-top: 1rem;
  }
`;

const LegalLink = styled(RouterLink)`
  color: #666;
  text-decoration: none;
  margin-bottom: 0.5rem;
  font-size: 0.75rem;
  font-weight: 400;
  transition: color 0.3s;

  &:hover {
    color: #ffa500;
  }

  @media (max-width: 768px) {
    font-size: 0.625rem;
  }
`;

const PaymentIcons = styled.div`
  display: flex;
  gap: 0.5rem;
  margin: 0.5rem 0;
  img {
    height: 25px;
  }

  @media (max-width: 768px) {
    justify-content: flex-start;
  }
`;

const Copyright = styled.p`
  color: #404040;
  font-size: 0.75rem;
  text-align: right;

  @media (max-width: 768px) {
    font-size: 0.625rem;
    text-align: left;
  }
`;

const Footer = () => {
  const navigate = useNavigate();

  const handleHomeClick = () => {
    navigate('/');
    window.scrollTo(0, 0);
  };

  return (
    <FooterContainer>
      <FooterContent>
        <Logo>
          <img src={logoFooter} alt="Logo" />
        </Logo>
        <NavSection>
          <Column>
            <ColumnTitle>Навигация</ColumnTitle>
            <FooterLink as="span" onClick={handleHomeClick}>Главная</FooterLink>
            <ExternalLink href="https://forum.premier-game.ru/">Форум</ExternalLink>
            <FooterLink to="/faq">Страница помощи</FooterLink>
            <FooterLink to="/shop">Магазин</FooterLink>
            <FooterLink to="/map">Карта</FooterLink>
          </Column>
          <Column>
            <ColumnTitle>Дополнительно</ColumnTitle>
            <FooterLink to="/forgot-password">Забыл пароль</FooterLink>
            <ExternalLink href="https://forum.premier-game.ru/forums/obnovlenija.195/">Обновления</ExternalLink>
            <ExternalLink href="https://forum.premier-game.ru/forums/informacija-dlja-novichkov-faq.25/">Информация для новичков</ExternalLink>
            <ExternalLink href="https://vk.me/premier_game">Обратная связь</ExternalLink>
            <ExternalLink href="https://forum.premier-game.ru/forums/rasprostranennye-problemy-razdel-reshenija-problem.16/">Технический раздел</ExternalLink>
          </Column>
        </NavSection>
        <LegalSection>
          <LegalLink to="/terms-of-service">Пользовательское соглашение</LegalLink>
          <LegalLink to="/privacy-policy">Политика конфиденциальности</LegalLink>
          <LegalLink to="/contacts">Контакты</LegalLink>
          <PaymentIcons>
            <img src={paymentIcon1} alt="SBP" />
            <img src={paymentIcon2} alt="CARD" />
          </PaymentIcons>
          <Copyright>© 2011 — 2025 PREMIER GAME. Все права защищены.</Copyright>
        </LegalSection>
      </FooterContent>
    </FooterContainer>
  );
};

export default Footer;
